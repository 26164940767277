
























import { Component, Prop, Vue } from 'vue-property-decorator';

import Group from '@/api/models/group';
import CoreSection from '@/components/utils/CoreSection.vue';
import FieldRow from '@/components/utils/FieldRow.vue';

@Component({
  components: {
    CoreSection,
    FieldRow,
  }
})
export default class UsersIndexGroupSection extends Vue {
  /**
   * グループ
   */
  @Prop({ required: true })
  group!: Group;

  titleWidth = 300; // 項目タイトルが折り返さない幅に調整
}
