import { classToPlain, plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';
import _ from 'lodash';

export type Form<T> = T & {
  readonly $original: T;
};

function clone<T>(constructor: ClassType<T>, instance: T): T {
  const plain = classToPlain(instance, { excludePrefixes: ['$'] });
  return plainToClass(constructor, plain, { excludePrefixes: ['$'] });
}

function _makeForm<T>(constructor: ClassType<T>, original?: T, copy?: T): Form<T> {
  original = original || new constructor();
  copy = copy || clone(constructor, original);
  (copy as unknown as { $original: T }).$original = original;
  return copy as Form<T>;
}

export function makeForm<T>(constructor: ClassType<T>, original?: T | Form<T>, copy?: T): Form<T> {
  if (original && '$original' in original) 
    // originalが$originalを持つ場合それを取り除く
    return _makeForm(constructor, clone(constructor, original), copy);
  else
    return _makeForm(constructor, original, copy);
}

export function extractForm<T>(form: Form<T>) {
  return classToPlain(form, { excludePrefixes: ['$'] });
}

export function makeArrayForm<T>(constructor: ClassType<T>, originals?: T[], copies?: T[]): Form<Array<T>> {
  originals = originals || [];
  copies = copies || originals.map(instance => clone(constructor, instance));
  (copies as unknown as { $original: Array<T> }).$original = originals;
  return copies as Form<Array<T>>;
}

export function changedForm<T>(form: Form<T>): boolean {
  const original = classToPlain(form.$original);
  const current = extractForm(form);
  return !_.isEqual(original, current);
}
