




























import { noop } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import User from '@/api/models/user';
import UsersTableRow from '@/components/UsersTableRow.vue';
import CoreTable from '@/components/utils/CoreTable.vue';

@Component({
  components: {
    CoreTable,
    UsersTableRow,
  }
})
export default class UsersTable extends Vue {
  /**
   * ユーザー一覧
   */
  @Prop({ required: true })
  users!: User[];

  /**
   * 削除コールバック関数
   */
  @Prop({ default: () => noop })
  remove!: (user: User) => Promise<void>;

}
