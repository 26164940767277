








































import { noop } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import User from '@/api/models/user';
import DeleteConfirmFormview from '@/components/utils/DeleteConfirmFormview.vue';

@Component({
  components: {
    DeleteConfirmFormview,
  }
})
export default class UsersTableRow extends Vue {

  /**
   * ユーザー
   */
  @Prop({ required: true })
  user!: User;

  /**
   * 削除コールバック関数
   */
  @Prop({ default: () => noop })
  remove!: (user: User) => Promise<void>;

  active = false;
  deleteConfirmDialog = false;

  async onRemove() {
    this.deleteConfirmDialog = false;
    this.active = true;
    await this.remove(this.user);
    this.active = false;
  }

  get disabledDelete() {
    return this.active;
  }

  get permissionKbn() {
    return this.user.permissionKbnArray.map(permission =>
      this.$t(`kbn.${permission}`)
    ).join(' / ');
  }
  get showDeleteButton() {
    // 作成できる権限のユーザーのみ削除させたいため
    return this.user.permissionKbnArray.length === 0;
  }
}
