











import { Component, Inject, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import UsersIndexTemplate from '@/components/UsersIndexTemplate.vue';
import { GlobalMe } from '@/store/global_me';

@Component({
  components: {
    UsersIndexTemplate,
  }
})
export default class UsersIndex extends Vue {
  @Inject()
  api!: Api;

  @Inject()
  globalMeModule!: GlobalMe;
}
