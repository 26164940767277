








import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Kbn, KbnValues } from '@/utils/kbn';

/**
 * 区分選択リスト `uv-select`のPropが利用可能
 */
@Component
export default class KbnSelect extends Vue {

  /**
   * 利用する区分
   */
  @Prop({ required: true })
  kbn!: Kbn;

  get items() {
    return _.map(KbnValues[this.kbn], (kbnValue) => {
      return {
        label: this.$t(`kbn.${kbnValue}`),
        value: kbnValue,
      };
    });
  }
}
