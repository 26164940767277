






























import { Component, Prop, Vue } from 'vue-property-decorator';

import Group from '@/api/models/group';
import DateText from '@/components/DateText.vue';
import CoreSection from '@/components/utils/CoreSection.vue';
import FieldRow from '@/components/utils/FieldRow.vue';

@Component({
  components: {
    CoreSection,
    FieldRow,
    DateText,
  }
})
export default class UsersIndexGroupPolicySection extends Vue {
  /**
   * グループポリシー
   */
  @Prop({ required: true })
  group!: Group;

  titleWidth = 300; // 項目タイトルが折り返さない幅に調整

  @Prop({ required: true})
  edit!: () => void;

}
