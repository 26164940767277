












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormviewFieldRow extends Vue {
  /**
   * 項目名
   */
  @Prop({ required: true })
  title!: string;

  /**
   * 必須
   */
  @Prop({ default: false })
  required!: boolean;
}
