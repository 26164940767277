

























import { Component, Vue } from 'vue-property-decorator';

/**
 * `uv-table`をラップしてスタイルを提供する
 */
@Component
export default class CoreTable extends Vue {
}
