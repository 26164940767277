























































import { Pagination } from '@uniquevision/libraries.beluga_ui/src/components/UvPageControls';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import Group from '@/api/models/group';
import Me from '@/api/models/me';
import User from '@/api/models/user';
import UsersSearchForm from '@/api/models/users_search_form';
import GroupPolicyForm from '@/components/GroupPolicyForm.vue';
import UserForm from '@/components/UserForm.vue';
import UsersIndexGroupPolicySection from '@/components/UsersIndexGroupPolicySection.vue';
import UsersIndexGroupSection from '@/components/UsersIndexGroupSection.vue';
import UsersTable from '@/components/UsersTable.vue';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';
import { GlobalMessageModule } from '@/store/global_message';
import { makeForm } from '@/utils/form';
import promiseNoop from '@/utils/promise_noop';;

@Component({
  components: {
    UsersIndexGroupSection,
    UsersTable,
    UserForm,
    UsersIndexGroupPolicySection,
    GroupPolicyForm,
  }
})
export default class UsersIndexTemplate extends Vue {
  /**
   * ログインユーザー取得
   */
  @Prop({ required: true })
  getMe!: () => Promise<Me>;

  /**
   * getUsers APIコールバック
   */
  @Prop({ required: true })
  getUsers!: (form: UsersSearchForm) => Promise<{ items: User[]; totalCount: number }>;

  /**
   * postUser APIコールバック
   */
  @Prop({ default: () => promiseNoop })
  postUser!: (user: User) => Promise<void>;

  /**
  * putGroup APIコールバック
  */
  @Prop({ default: () => promiseNoop })
  putGroup!: (group: Group) => Promise<void>;

  /**
   * deleteUser APIコールバック
   */
  @Prop({ default: () => promiseNoop })
  deleteUser!: (user: User) => Promise<void>;

  /**
   * getGroup APIコールバック
   */
  @Prop({ required: true })
  getGroup!: (groupUuid: string) => Promise<Group>;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  @Inject()
  globalMessageModule!: GlobalMessageModule;

  // 検索条件周りのメンバ
  usersSearchForm = makeForm(UsersSearchForm);
  pagination: Pagination = {start:0, end:0, total: 0};
  totalCount = 0;
  users: User[] = [];

  // 新規作成周りのメンバ
  newUserForm = makeForm(User);
  userFormDialog = false;

  // グループポリシー編集のメンバ
  editGroupPolicyForm = makeForm(Group);
  editGroupPolicyFormDaialog = false;

  // グループ情報
  group = new Group();

  created() {
    this.usersSearchForm.resetPage();
    this.onSearch();
    this.loadGroup();
  }

  onSeek(page: number) {
    this.usersSearchForm.page = page;
    this.onSearch();
  }

  onNew() {
    this.newUserForm = makeForm(User, new User({
      name: '',
      mail: '',
    }));
    this.userFormDialog = true;
  }

  onGroupPolicyEdit() {
    this.editGroupPolicyForm = makeForm(Group, new Group({
      uuid: this.group.uuid,
      policyConfirm: this.group.policyConfirm,
      policyMessage: this.group.policyMessage,
      policyValidDate: this.group.policyValidDate,
      userConfirmReset: false
    }));
    this.editGroupPolicyFormDaialog = true;
  }

  async onSearch() {
    const res = await this.globalLoadingScreenModule.track(this.getUsers(this.usersSearchForm));
    this.users = res.items;
    this.totalCount = res.totalCount;
  }

  async onSubmit() {
    await this.globalLoadingScreenModule.track(this.postUser(this.newUserForm));
    this.usersSearchForm.resetPage();
    await this.onSearch();
    this.globalMessageModule.setMessages('info', [`「${this.newUserForm.name}」ユーザーが登録されました`]);
    this.userFormDialog = false;
  }

  async onGroupPolicySubmit() {
    await this.globalLoadingScreenModule.track(this.putGroup(this.editGroupPolicyForm));
    await this.loadGroup();
    this.globalMessageModule.setMessages('info', ['グループポリシーが更新されました']);
    this.editGroupPolicyFormDaialog = false;
  }

  async onDelete(user: User) {
    await this.globalLoadingScreenModule.track(this.deleteUser(user));
    await this.onSearch();
    this.globalMessageModule.setMessages('info', [`「${user.name}」ユーザーを削除しました。`]);
  }

  async loadGroup() {
    const me = await this.globalLoadingScreenModule.track(this.getMe());
    this.group = await this.globalLoadingScreenModule.track(this.getGroup(me.groupUuid));
  }

  get limited() {
    const maximumUserCount = this.group.maximumUserCount || 0;
    return this.users.length >= maximumUserCount;
  }

  get disabledCreate() {
    return this.limited;
  }
}
