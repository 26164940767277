












































































import _ from 'lodash';
import { Component, Mixins, Prop } from 'vue-property-decorator';

import APIError from '@/api/api_error';
import Group from '@/api/models/group';
import KbnSelect from '@/components/KbnSelect.vue';
import FormviewFieldRow from '@/components/utils/FormviewFieldRow.vue';
import FormviewLayout from '@/components/utils/FormviewLayout.vue';
import GroupPolicySubmitConfirmFormview from '@/components/utils/GroupPolicySubmitConfirmFormview.vue';
import LeaveConfirmFormview from '@/components/utils/LeaveConfirmFormview.vue';
import Validatable from '@/mixins/validatable';
import { ErrorConst } from '@/utils/error_const';
import { changedForm, Form } from '@/utils/form';

@Component({
  components: {
    FormviewLayout,
    FormviewFieldRow,
    KbnSelect,
    LeaveConfirmFormview,
    GroupPolicySubmitConfirmFormview,
  }
})
export default class GroupPolicyForm extends Mixins(Validatable) {

  /**
   * グループ
   */
  @Prop({ required: true})
  value!: Form<Group>;
 
  /**
   * 保存コールバック
   */
  @Prop({ default: () => _.noop })
  submit!: () => Promise<void>;

  /**
   * キャンセルコールバック
   */
  @Prop({ default: () => _.noop })
  cancel!: () => Promise<void>;

  leaveConfirmDialog = false;
  submitConfirmDialog = false;
  active = false;
  errors: Partial<Record<keyof Group, string>> = {};
  dummyValue = false;

  async onSubmit() {
    if (this.active) return;
    try {
      this.active = true;
      this.submitConfirmDialog = false;
      await this.submit();
    } catch (error) {
      if (error instanceof APIError && error.resultCode === ErrorConst.COMMON_INVALID_PARAMETER) {
        this.errors = error.errors;
      } else {
        throw error;
      }
    } finally {
      this.active = false;
    }
  }

  get disabledSubmit() {
    return this.active;
  }

  onOpenLeaveDialog() {
    if (!changedForm(this.value)) {
      this.cancel();
    } else {
      this.leaveConfirmDialog = true;
    }
  }

  async onSubmitConfirm() {
    this.errors = await this.validate(this.value);
    if (_.isEmpty(this.errors)) {
      this.submitConfirmDialog = true;
    }
  }
}
